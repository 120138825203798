import { curry, pathSatisfies, test, identity, path } from "ramda";

const propFilter = curry((pathList, regex) => pathSatisfies(test(regex), pathList));

/** Extract localization file from folder by specific name. */
export function findNodeBy(nodes, filePattern, dirPattern) {
  const filterByFileName = propFilter(["fields", "fileName"]);
  const filterByDirectoryName = propFilter(["fields", "directoryName"]);

  if (dirPattern) {
    nodes = nodes.filter(filterByDirectoryName(dirPattern));
  }

  return nodes.find(filterByFileName(filePattern)) || {};
}

/**
 * Sort all nodes to variables for easier processing.
 */
export default function breakDownAllNodes(nodes) {
  const filterByFileName = propFilter(["fields", "fileName"]);
  const filterByDirectoryName = propFilter(["fields", "directoryName"]);

  // top part
  const topNode = nodes.find(filterByFileName(/^top/i)) || {};
  // navbar
  const navBarNode = nodes.find(filterByFileName(/navbar/i)) || {};
  // footer
  const footerNode = nodes.find(filterByFileName(/footer/i)) || {};

  // sections part
  const sectionsNodes = nodes.filter(filterByDirectoryName(/sections/i));
  // landings
  const landingsNodes = nodes.filter(filterByDirectoryName(/landings/i));

  // anchors for NavBar
  const anchors = sectionsNodes.map(path(["i18n", "anchor"])).filter(identity);

  return {
    topNode,
    navBarNode,
    footerNode,
    sectionsNodes,
    landingsNodes,
    anchors,
  };
}
